import React from "react";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Logo from "../../components/Logo";
import Layout from "../../components/Layout";

import Navigation from "../../components/NavigationWithLogo";
import "../../style/all.css";
import Img from "gatsby-image";
import $ from "jquery";
import "react-photoswipe/lib/photoswipe.css";
import { PhotoSwipeGallery } from "react-photoswipe";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

class BlogIndex extends React.Component {
  state = {
    isOpen: false,
    items: [],
    options: {
      closeOnScroll: false,
    },
    images: [],
    contentWithoutImages: "",
  };

  getThumbnailContent = (item) => (
    <img
      src={item.thumbnail}
      style={{ height: "150px", width: "auto" }}
      alt=""
    />
  );

  openPhotoSwipe = (e) => {
    e.preventDefault();
    this.setState({
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    // if(post.node.format === "gallery"){
    //   gallery()
    // }
    // else {
    //   contentWithoutImages = post.node.content
    // }
    const renderOptions = {
      renderMark: {
        [MARKS.CODE]: (text) => (
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        ),
        [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      },
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          return (
            <PhotoSwipeGallery
              items={node.data.target.images.map((image) => {
                return {
                  src: image.file.url,
                  w: image.file.details.image.width,
                  h: image.file.details.image.height,
                  thumbnail: image.fixed.src,
                  // thumbnailSize: thumbnailSize,
                };
              })}
              options={options}
              thumbnailContent={this.getThumbnailContent}
              isOpen={isOpen}
              onClose={this.handleClose}
              className="the-gallery"
            />
          );
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          if (!node?.data?.target) {
            return null;
          }
          const { gatsbyImageData } = node.data.target;
          if (!gatsbyImageData) {
            // asset is not an image
            return null;
          }
          return <img srcSet={gatsbyImageData.images.sources[0].srcSet}></img>;
        },
      },
    };

    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const post = get(this, "props.data.allContentfulHomepage.nodes")[0];

    const { options, isOpen } = this.state;

    return (
      <Layout>
        <Logo />
        <Navigation menuText="Vita" />

        <div>
          <Helmet title={`Vita | ${siteTitle}`}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
            <base target="_blank" />
          </Helmet>
          <section className="section-vita">
            <div className="content">
              <article>
                <h1>Vita</h1>
                <div className="list-entry">
                  <div className="section-content">
                    {/* <div
                      className="blog-content"
                      dangerouslySetInnerHTML={{
                        __html: post.aboutFullText.childMarkdownRemark.html,
                      }}
                    /> */}
                    <div>
                      {post.aboutFullRichText &&
                        renderRichText(post.aboutFullRichText, renderOptions)}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query VitaQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHomepage {
      nodes {
        aboutFullRichText {
          references {
            ... on ContentfulAsset {
              __typename
              contentful_id
              gatsbyImageData
              id
              file {
                url
              }
              title
            }
            ... on ContentfulGallery {
              contentful_id
              __typename
              title
              images {
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                  url
                }
                fixed(height: 300) {
                  src
                }
              }
            }
          }
          raw
        }
      }
    }
  }
`;
